import React from 'react';
import Head from 'next/head';

import EnplusLayout from '@studionand/depi/src/components/enplus/Layout';
import Header from '@studionand/depi/src/components/enplus/Header';
import StatsSection from '@studionand/depi/src/components/enplus/StatsSection';
import InternalSection from '@studionand/depi/src/components/enplus/InternalSection';
import FeatureSection from '@studionand/depi/src/components/enplus/FeatureSection';
import ThemesSection from '@studionand/depi/src/components/enplus/ThemesSection';
import ButtonSection from '@studionand/depi/src/components/enplus/ButtonSection';

const EnplusHackschnitzelPage = props => {
  return (
    <EnplusLayout context="hackschnitzel" hideNavPhotoEdge>
      <Head>
        <title>ENplus Hackschnitzel - Startseite</title>
      </Head>
      <Header context="hackschnitzel" />
      <StatsSection context="hackschnitzel" />
      <ButtonSection />
      <ThemesSection />
      <InternalSection />
      <FeatureSection />
    </EnplusLayout>
  );
};

EnplusHackschnitzelPage.getInitialProps = async ({ dataStore, uiStore }) => {
  await dataStore.pages.fetchFeatures(uiStore.context);
  return {};
};

export default EnplusHackschnitzelPage;
